import SourceFlowImage from "@sourceflow-uk/sourceflowimage"
import SourceFlowText from "@sourceflow-uk/sourceflowtext"
import {
    Container,
    Row,
    Col,
    NavItem,
    Nav,
    Card
} from 'reactstrap';
import Link from "next/link"
import styles from './styles.module.scss';
// import Marquee from "react-marquee-slider";

import Arrow from "/public/site-assets/svg/black-arrow.svg"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from "swiper"

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

export default function LatestAwards({
    global,
    pathPrefix,
    customClass = "",
    content,
    post,
    buttonLink,
    buttonTitle,
    swiperID,
}) {
    const settings = {
        modules: [Navigation, Pagination, Autoplay],
        loop: false,
        spaceBetween: 30,
        slidesPerView: 7,
        speed: 400,
        autoplay: {
            delay: 3000,
        },
        pagination: {
            clickable: true,
            el: `.swiper-custom-paginations .${swiperID}`,
        },
        navigation: {
            nextEl: `.${swiperID}.swiper-next`,
            prevEl: `.${swiperID}.swiper-prev`
        },
        breakpoints: {
            1200: {
                slidesPerView: 7,
            },
            991: {
                slidesPerView: 3,
            },
            767: {
                slidesPerView: 2,
            },
            300: {
                slidesPerView: 2,
            }
        }
    }
    return (
        post?.length >= 1 && (
            <section className={`${styles.root} ${customClass} overflow-hidden`} >
                <Container>
                    {content ?
                        <SourceFlowText global={global} path={`${pathPrefix}.content`} type="html">
                            {content}
                        </SourceFlowText>
                        : ""}
                    {/* <Row className="my-5 justify-content-center align-items-center"> */}
                    <div>
                    <Swiper {...settings}>
                        {post.map((post, index) => (
                            <SwiperSlide key={index}>
                                <Card key={index} className="rounded-0 bg-transparent border-0 align-items-center">
                                    {post?.link ?
                                        <Link href={`${post?.link}`}>
                                            <a title={`${post?.title}`}>
                                                <SourceFlowImage
                                                    src={post?.image}
                                                    size="500x"
                                                    alt={post?.title}
                                                    width="100%"
                                                    height="100%"
                                                    className="card-img rounded-0 m-auto"
                                                /></a></Link> : <SourceFlowImage
                                            src={post?.image}
                                            size="500x"
                                            alt={post?.title}
                                            width="100%"
                                            height="100%"
                                            className="card-img rounded-0 m-auto"
                                        />}

                                </Card>
                            </SwiperSlide>
                        
                        ))}
                    </Swiper>
                    </div> <div className="text-center swiper-custom-paginations mt-4">
                        <div className={`${swiperID}`} />
                    </div>
                  
                    {/* </Row> */}
                    {buttonLink ?
                        <div className="text-center">
                            <Link href={buttonLink}>
                                <a className="primaryBtn no-arrow m-0">{buttonTitle}</a>
                            </Link>
                        </div> : ""}
                </Container>
            </section>
        )
    )
}